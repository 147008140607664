import * as _ from 'lodash';
import * as React from 'react';
import * as CSSTypes from '../types/css';
type FlexDirection = 'column' | 'row';

export function removeDefaultButtonStyling(): {} {
  return {
    margin: 0,
    padding: 0,
    border: 'none',
    backgroundColor: 'transparent',
    font: 'inherit',
    lineHeight: 'normal',
    cursor: 'pointer',
    '::-moz-focus-inner': {
      padding: 0,
      border: 0,
    },
  };
}

/**
 * Function that will produce a flex container that centers all children
 */
export function flexCentered(flexDirection: FlexDirection): React.CSSProperties {
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection,
  } as React.CSSProperties;
}

/**
 * Function that will have a dimension applied to both height and width
 */
export function square(dimension: number | string): React.CSSProperties {
  return {
    width: dimension,
    height: dimension,
  };
}

/**
 * Utility function to combine style mixins.
 */
export function combineStyles(...styles: CSSTypes.CSSProperties[]): CSSTypes.CSSProperties {
  return _.merge({}, ...styles) as CSSTypes.CSSProperties;
}
