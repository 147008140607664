import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import Colors from '../../styles/colors';
import * as mixins from '../../styles/mixins';
import * as typography from '../../styles/typography';

const styles = StyleSheet.create({
  headerStyle: mixins.combineStyles(
    typography.OpenSans(),
    {
      color: Colors.INDIGO,
      fontSize: 45,
    },
  ),
});

export default class Header extends React.Component<{}, {}> {
  render() {
    const { children } = this.props;
    return <h3 className={css(styles.headerStyle)}>{children}</h3>;
  }
}
