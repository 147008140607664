import * as React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';

import {
  App,
  Home,
} from './components';

// TODO: Add the the other routes (Work and Resume) later.
const routeConfiguration = (
  <Router>
    <App>
      <Route exact path="/" component={Home} />
    </App>
  </Router>
);

export default routeConfiguration;
