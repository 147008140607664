import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { RouteProps } from '../../types/route';
import Colors from '../../styles/colors';
import * as mixins from '../../styles/mixins';
import Page from '../Page/Page';
import Button from '../Button/Button';
import Header from '../Header/Header';
import Text from '../Text/Text';

const portrait = require('./portrait.jpg');

const styles = StyleSheet.create({
  homeBody: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 950,
  },
  pictureContainer: mixins.combineStyles(
    mixins.square(250),
    {
      borderRadius: '100%',
      overflow: 'hidden',
    },
  ),
  picture: {
    width: 273,
    height: 'auto',
    display: 'inline',
    transform : 'translate(0px, -55px)'

  },
  homeDescription: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: 600,
  },
  employerStyle: {
    fontSize: 25,
    color: Colors.BLUE,
    textDecoration: 'none',
  },
  headerContainer: {
    paddingBottom: 35,
  },
  textContainer: {
    paddingBottom: 20,
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 340,
  },
  lastItem: {
    paddingBottom: 30,
  },
  buttonContainer: {
    width: 150,
  },
});
export default class Home extends React.Component<RouteProps, {}> {
  render() {
    const { history, location } = this.props;
    return (
      <Page isFixed history={history} location={location}>
        <div className={css(styles.homeBody)}>
          <div className={css(styles.pictureContainer)}>
            <img className={css(styles.picture)} src={portrait} />
          </div>
          <div className={css(styles.homeDescription)}>
            <div className={css(styles.headerContainer)}>
              <Header>John Li</Header>
            </div>
            <div className={css(styles.textContainer)}>
              <Text>I'm a developer, currently residing in San Francisco, California.</Text>
            </div>
            <div className={css(styles.textContainer)}>
              <Text>I pair empathy with engineering to build delightful software.</Text>
            </div>
            <div className={css(styles.textContainer, styles.lastItem)}>
              <Text>Currently employed by: <a className={css(styles.employerStyle)} href='https://www.blend.com' rel='external'>Blend</a></Text>
            </div>
            <div className={css(styles.buttons)}>
              <div className={css(styles.buttonContainer)}>
                <Button>GITHUB</Button>
              </div>
              <div className={css(styles.buttonContainer)}>
                <Button>CONTACT</Button>
              </div>
            </div>
          </div>
         </div>
      </Page>
    );
  }
}
