import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';

import Colors from '../../styles/colors';
import * as mixins from '../../styles/mixins';
import * as typography from '../../styles/typography';

const styles = StyleSheet.create({
  inactiveStyle: mixins.combineStyles(
    typography.Montserrat(),
    {
      display: 'block',
      color: Colors.BLUE,
      // Kinda hacky, but it will have a fade in effect as it transitions to another color.
      borderBottom: '3px solid transparent',
      fontSize: 16,
      transition: 'transform 0.2s ease-out, color 0.2s, border-bottom 0.2s',
      paddingBottom: 3,
      ':hover': {
        transform: 'translateY(-2px)',
        color: Colors.INDIGO,
        borderBottom: `3px solid ${Colors.INDIGO}`,
      },
    },
  ),
  activeStyle: mixins.combineStyles(
    typography.Montserrat(),
    {
      display: 'block',
      color: Colors.INDIGO,
      borderBottom: `3px solid ${Colors.INDIGO}`,
      fontSize: 16,
      paddingBottom: 3,
    },
  ),
})

export default class NavbarText extends React.Component<{}, {}> {
  render() {
    const { children } = this.props;
    return <span className={css(styles.inactiveStyle)}>{children}</span>;
  }
}
