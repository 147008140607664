import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { RouteProps } from '../../types/route';
import * as mixins from '../../styles/mixins';
import Colors from '../../styles/colors';
import Navbar from  '../Navbar/Navbar';

const styles = StyleSheet.create({  
  fixedScreen: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    position: 'relative',
    height: '100vh',
    width: '100vw',
    backgroundImage: `linear-gradient(12deg, ${Colors.LIGHT_BLUE} 35%, transparent 35%)`,
  },
  longScreen: {
    position: 'relative',
    width: '100vw',
    backgroundImage: `linear-gradient(12deg, ${Colors.LIGHT_BLUE} 35%, transparent 35%)`,
  },
  childrenContainer: mixins.flexCentered('column'),
  navBar: mixins.combineStyles(
    mixins.flexCentered('row'),
    {
      width: '85%',
      height: 100,
      paddingBottom: 75,
    },
  ),
});

interface PageProps extends RouteProps {
  isFixed: boolean;
}

export default class Page extends React.Component<PageProps, {}> {
  render() {
    const { isFixed, history, location, children } = this.props;
    const screenClass = (isFixed ? styles.fixedScreen : styles.longScreen) as React.CSSProperties;
    return (
      <div className={css(screenClass)}>
        <div className={css(styles.navBar)}><Navbar history={history} location={location} /></div>
        <div className={css(styles.childrenContainer)}>
          {children}
        </div>
      </div>
    );
  }
}
