import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import * as mixins from '../../styles/mixins';
import Colors from '../../styles/colors';
import * as typography from '../../styles/typography';

const styles = StyleSheet.create({
  textStyle: mixins.combineStyles(
    typography.Montserrat(),
    {
      color: Colors.INDIGO,
      fontSize: 20,
    },
  ),
});

export default class Text extends React.Component<{}, {}> {
  render() {
    const { children } = this.props;
    return <span className={css(styles.textStyle)}>{children}</span>;
  }
}
