import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
// TODO: Do mixins later for responsiveness.
import Colors from '../../styles/colors';

const styles = StyleSheet.create({
  appBackground: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    backgroundColor: Colors.BG_BLUE,
  }
});

// Component contains background and also navigation bar.
export default class App extends React.Component<{}, {}> {
  render() {
    const { children } = this.props;
    return (
      <div className={css(styles.appBackground)}>
        {children}
      </div>
    );
  }
}
