import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { RouteProps } from '../../types/route';

import NavbarText from './NavbarText';

const Icon = require('./jli.svg');

const styles = StyleSheet.create({
  imageContainer: {
    height: 40,
  },
  navbarItem: {
    paddingRight: 35,
  },
  navbarContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  categoryContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
});

export default class Navbar extends React.Component<RouteProps, {}> {
  render() {
    return (
      <div className={css(styles.navbarContainer)}>
        <img className={css(styles.imageContainer)} src={Icon} />
        <div className={css(styles.categoryContainer)}>
          <div className={css(styles.navbarItem)}><NavbarText>Work</NavbarText></div>
          <div><NavbarText>Resume</NavbarText></div>
        </div>
      </div>
    );
  }
}
