
const BG_BLUE = '#F7FEFF';
const LIGHT_BLUE = '#DDEBEF';
const BLUE = '#4E8596';
const INDIGO = '#0B3B4F';
const PINK = '#FF7D87';
const WHITE = '#FFFFFF';

const Colors = {
  BG_BLUE,
  LIGHT_BLUE,
  BLUE,
  INDIGO,
  PINK,
  WHITE,
};

export default Colors;
