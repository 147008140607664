import * as CSSTypes from '../types/css';

export function OpenSans(): CSSTypes.CSSProperties {
  return {
    fontFamily: 'Open Sans',
    '-webkit-font-smoothing': 'antialiased',
    lineHeight: 'normal',
  };
}

export function Montserrat(): CSSTypes.CSSProperties {
  return {
    fontWeight: 300,
    fontFamily: 'Montserrat',
    '-webkit-font-smoothing': 'antialiased',
    lineHeight: 'normal',
  };
}
