import * as React from 'react';
import { css, StyleSheet } from 'aphrodite';
import * as mixins from '../../styles/mixins';
import Colors from '../../styles/colors';
import * as typography from '../../styles/typography'

const styles = StyleSheet.create({
  buttonStyle: mixins.combineStyles(
    mixins.removeDefaultButtonStyling(),
    typography.OpenSans(),
    mixins.flexCentered('row'),
    {
      letterSpacing: 1,
      color: Colors.WHITE,
      backgroundColor: Colors.PINK,
      borderRadius: 100,
      padding: 10,
      width: '100%',
    },
  ),
});

export default class Button extends React.Component<React.AllHTMLAttributes<HTMLButtonElement>, {}> {
  render() {
    const { children, ...htmlProps } = this.props;
    return <button className={css(styles.buttonStyle)} {...htmlProps}>{children}</button>;
  }
}
