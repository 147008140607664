import App from './App/App';
import Button from './Button/Button';
import Header from './Header/Header';
import Home from './Home/Home';
import Page from './Page/Page';
import Text from './Text/Text';

export {
  App,
  Button,
  Header,
  Home,
  Page,
  Text,
};
